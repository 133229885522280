import SvgIcon from '@mui/material/SvgIcon';

function LevelDownIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12.75 21L12.2109 20.4844L5.83594 14.1094L6.91406 13.0298L12 18.1172L12 4.5H4.5V3H12H13.5L13.5 18.1172L18.5859 13.0312L19.6641 14.1094L13.2891 20.4844L12.75 21Z' />
        </SvgIcon>
    );
}
export default LevelDownIcon;
