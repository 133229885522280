/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    TextField,
    Link,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LogoIcon from '../../components/icons/LogoIcon';
import AuthService from '../../api/auth.service';

function RestorePasswordPage() {
    useEffect(() => {
        document.title = 'Восстановление пароля';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница восстановления пароля');
    });

    const [open, setOpen] = React.useState(false);

    const [inputDisabler, setInputDisabler] = React.useState(false);

    const [buttonHider, setButtonHider] = React.useState(false);

    const [alertHider, setAlertHider] = React.useState('none');

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Обязательное поле')
            .email('Некорректный email'),
    });

    // const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        setOpen(true);
        setInputDisabler(true);
        setButtonHider(true);
        setAlertHider('flex');
        AuthService.restorePasswordViaEmail(data.email);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '320px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    <LogoIcon
                        sx={{
                            width: 32,
                            height: 32,
                            marginBottom: 4,
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h1'
                        sx={{ marginBottom: 1 }}
                    >
                        Восстановление пароля
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Вспомнили пароль?
                        <Link
                            component={RouterLink}
                            to='/sign-in'
                            variant='body2'
                            sx={{ paddingLeft: 0.5 }}
                        >
                            Войти
                        </Link>
                    </Typography>
                </Box>
                <Alert
                    severity='success'
                    disabled={inputDisabler}
                    sx={{
                        mt: '16px',
                        width: '100%',
                        display: alertHider,
                    }}
                >
                    Проверьте свою почту
                </Alert>
                <Box
                    component='form'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='email'
                        placeholder='Введите свой email'
                        label='Email'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        disabled={inputDisabler}
                        {...register('email')}
                        error={!!errors.email}
                        helperText={errors?.email ? errors.email.message : null}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        disabled={buttonHider}
                        sx={{ mt: 2, mb: 2, borderRadius: 26 }}
                    >
                        Восстановить пароль
                    </Button>
                </Box>
                <Typography
                    variant='body2'
                    sx={{ mt: 5 }}
                    color='text.secondary'
                    align='center'
                >
                    {'Copyright © '}
                    <Link component={RouterLink} to='/' color='inherit'>
                        Standfin
                    </Link>{' '}
                    {new Date().getFullYear()}.
                </Typography>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title' variant='h2'>
                    Сброс пароля
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Мы проверим принадлежность вашей почты к аккаунту и
                        вышлем инструкцию для восстановления
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default RestorePasswordPage;
