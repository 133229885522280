/* eslint-disable indent */
import { Link as ReactLink } from 'react-router-dom';
import {
    Box,
    Link,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import { useEffect } from 'react';

const columns = [
    { id: 'id', label: '№', maxWidth: 64, align: 'left' },
    { id: 'date', label: 'Дата', minWidth: 220, align: 'left' },
    { id: 'tariff', label: 'Тариф', minWidth: 220, align: 'left' },
    {
        id: 'paymentType',
        label: 'Способ оплаты',
        minWidth: 220,
        align: 'left',
    },
    { id: 'status', label: 'Статус', minWidth: 220, align: 'left' },
];

function createData(id, date, tariff, paymentType, status) {
    return { id, date, tariff, paymentType, status };
}

const rows = [
    createData('1', '19.06.2022', 'Премиум', 'Оплата по счету', 'Оплачен'),
    createData('2', '19.05.2022', 'Премиум', 'Оплата по счету', 'Оплачен'),
    createData('3', '19.04.2022', 'Премиум', 'Оплата по счету', 'Оплачен'),
    createData('4', '19.03.2022', 'Премиум', 'Оплата по счету', 'Оплачен'),
    createData('5', '19.02.2022', 'Премиум', 'Оплата картой', 'Оплачен'),
    createData('6', '19.01.2022', 'Премиум', 'Оплата картой', 'Оплачен'),
];

function AccountPaymentStoryPage() {
    useEffect(() => {
        document.title = 'История платежей';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница истории платежей');
    }, []);

    return (
        <>
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: 'background.blueExtraLight',
                    backgroundColor: '#ffffff',
                    marginLeft: '-32px',
                    marginRight: '-32px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                }}
            >
                <Link
                    component={ReactLink}
                    to='/profile'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Основные настройки
                </Link>
                <Link
                    component={ReactLink}
                    to='/profile/change-password'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Смена пароля
                </Link>
                <Link
                    component={ReactLink}
                    to='/profile/tariffs'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Тарифы и оплата
                </Link>
                <Box
                    component='div'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.primary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        backgroundColor: '#F1F5F9',
                        borderRadius: '12px 12px 0 0',
                        border: '1px solid',
                        borderColor: 'background.blueExtraLight',
                        borderBottomColor: '#F1F5F9',
                        marginBottom: '-1px',
                    }}
                >
                    История платежей
                </Box>
            </Box>
            <Box
                component='div'
                sx={{
                    paddingTop: '32px',
                    paddingBottom: '32px',
                }}
            >
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label='Таблица истории платежей'
                        stickyHeader
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}

export default AccountPaymentStoryPage;
