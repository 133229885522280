/* eslint-disable indent */
import { Link as ReactLink } from 'react-router-dom';
import { Box, Link, Typography, Button, Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CheckIcon from '../../components/icons/CheckIcon';

const Item = styled(Paper)(() => ({
    backgroundColor: '#fff',
    padding: '40px 48px',
    borderRadius: '16px',
    minHeight: '100%',
}));

function AccountTariffsPage() {
    useEffect(() => {
        document.title = 'Тарифы и оплата';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница тарифов и оплаты');
    }, []);

    return (
        <>
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: 'background.blueExtraLight',
                    backgroundColor: '#ffffff',
                    marginLeft: '-32px',
                    marginRight: '-32px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                }}
            >
                <Link
                    component={ReactLink}
                    to='/profile'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Основные настройки
                </Link>
                <Link
                    component={ReactLink}
                    to='/profile/change-password'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Смена пароля
                </Link>
                <Box
                    component='div'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.primary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        backgroundColor: '#F1F5F9',
                        borderRadius: '12px 12px 0 0',
                        border: '1px solid',
                        borderColor: 'background.blueExtraLight',
                        borderBottomColor: '#F1F5F9',
                        marginBottom: '-1px',
                    }}
                >
                    Тарифы и оплата
                </Box>
                <Link
                    component={ReactLink}
                    to='/profile/payment-story'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    История платежей
                </Link>
            </Box>
            <Box
                component='div'
                sx={{
                    paddingTop: '32px',
                    paddingBottom: '32px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                        flex: '1 1 auto',
                        padding: '64px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '1280px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant='h2'
                                sx={{
                                    textTransform: 'uppercase',
                                    textAlign: 'center',
                                }}
                            >
                                Тарифы и цены
                            </Typography>
                            <Typography
                                variant='h1'
                                sx={{
                                    fontSize: '48px',
                                    lineHeight: '64px',
                                    fontWeight: '700',
                                    textAlign: 'center',
                                }}
                            >
                                Полный контроль над финансами
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                sx={{
                                    textAlign: 'center',
                                    color: '#6B7280',
                                    lineHeight: '28px',
                                    marginTop: '12px',
                                }}
                            >
                                Первые 15 дней бесплатно. Пролонгирование в
                                любое время.
                                <br /> Любые способы оплаты
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: '#E4E8EC',
                                    marginTop: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '2px',
                                    borderRadius: '26px',
                                }}
                            >
                                <Button
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        borderRadius: '26px',
                                        backgroundColor: '#ffffff',
                                        color: 'text.primary',
                                        '&:hover': {
                                            color: '#fff',
                                        },
                                    }}
                                >
                                    Годовая подписка
                                </Button>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        borderRadius: '26px',
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                        color: 'text.primary',
                                        '&:hover': {
                                            color: '#fff',
                                        },
                                    }}
                                >
                                    Месячная подписка
                                </Button>
                            </Box>
                        </Box>
                        <Grid container spacing={4} sx={{ marginTop: '64px' }}>
                            <Grid item xs={4}>
                                <Item>
                                    <Typography variant='h2'>
                                        Personal
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            marginTop: '8px',
                                        }}
                                    >
                                        Perfect for an individual or a small
                                        team starting to get bigger
                                    </Typography>
                                    <Typography
                                        sx={{
                                            marginTop: '80px',
                                            fontSize: '40px',
                                            lineHeight: '48px',
                                            fontWeight: '700',
                                            color: 'text.primary',
                                        }}
                                    >
                                        1 990 ₽
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                        sx={{
                                            marginTop: '8px',
                                        }}
                                    >
                                        billed yearly
                                    </Typography>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            borderRadius: '26px',
                                            backgroundColor: '#ffffff',
                                            color: 'text.primary',
                                            width: '100%',
                                            marginTop: '40px',
                                            '&:hover': {
                                                color: '#fff',
                                            },
                                        }}
                                    >
                                        Выбрать и оплатить
                                    </Button>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'
                                        sx={{
                                            marginTop: '48px',
                                            fontWeight: '600',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        В стоимость тарифы включены:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                            sx={{
                                                fontWeight: '600',
                                                marginRight: '4px',
                                            }}
                                        >
                                            10
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            projects
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                            sx={{
                                                fontWeight: '600',
                                                marginRight: '4px',
                                            }}
                                        >
                                            4Gb
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            storage
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            Analytics
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>
                                    <Typography variant='h2'>
                                        Personal
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            marginTop: '8px',
                                        }}
                                    >
                                        Perfect for an individual or a small
                                        team starting to get bigger
                                    </Typography>
                                    <Typography
                                        sx={{
                                            marginTop: '80px',
                                            fontSize: '40px',
                                            lineHeight: '48px',
                                            fontWeight: '700',
                                            color: 'text.primary',
                                        }}
                                    >
                                        1 990 ₽
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                        sx={{
                                            marginTop: '8px',
                                        }}
                                    >
                                        billed yearly
                                    </Typography>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            borderRadius: '26px',
                                            width: '100%',
                                            marginTop: '40px',
                                            '&:hover': {
                                                color: '#fff',
                                            },
                                        }}
                                    >
                                        Выбрать и оплатить
                                    </Button>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'
                                        sx={{
                                            marginTop: '48px',
                                            fontWeight: '600',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        В стоимость тарифы включены:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                            sx={{
                                                fontWeight: '600',
                                                marginRight: '4px',
                                            }}
                                        >
                                            10
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            projects
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                            sx={{
                                                fontWeight: '600',
                                                marginRight: '4px',
                                            }}
                                        >
                                            8Gb
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            storage
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            Analytics
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            Free mobile app
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item>
                                    <Typography variant='h2'>
                                        Personal
                                    </Typography>
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            marginTop: '8px',
                                        }}
                                    >
                                        Perfect for an individual or a small
                                        team starting to get bigger
                                    </Typography>
                                    <Typography
                                        sx={{
                                            marginTop: '80px',
                                            fontSize: '40px',
                                            lineHeight: '48px',
                                            fontWeight: '700',
                                            color: 'text.primary',
                                        }}
                                    >
                                        1 990 ₽
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                        sx={{
                                            marginTop: '8px',
                                        }}
                                    >
                                        billed yearly
                                    </Typography>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            borderRadius: '26px',
                                            backgroundColor: '#ffffff',
                                            color: 'text.primary',
                                            width: '100%',
                                            marginTop: '40px',
                                            '&:hover': {
                                                color: '#fff',
                                            },
                                        }}
                                    >
                                        Выбрать и оплатить
                                    </Button>
                                    <Typography
                                        variant='body2'
                                        color='text.primary'
                                        sx={{
                                            marginTop: '48px',
                                            fontWeight: '600',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        В стоимость тарифы включены:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                            sx={{
                                                fontWeight: '600',
                                                marginRight: '4px',
                                            }}
                                        >
                                            10
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            projects
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                            sx={{
                                                fontWeight: '600',
                                                marginRight: '4px',
                                            }}
                                        >
                                            8Gb
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            storage
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            Analytics
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            Free mobile app
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: '8px',
                                        }}
                                    >
                                        <CheckIcon
                                            sx={{
                                                fill: '#43A047',
                                                marginRight: '2px',
                                            }}
                                        />
                                        <Typography
                                            variant='body2'
                                            color='text.primary'
                                        >
                                            Access to forums
                                        </Typography>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default AccountTariffsPage;
