import SvgIcon from '@mui/material/SvgIcon';

function SignOutIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 3C7.03711 3 3 7.03711 3 12C3 16.9629 7.03711 21 12 21C15.0381 21 17.7305 19.4912 19.3594 17.1797L18.1406 16.3125C16.7842 18.2402 14.543 19.5 12 19.5C7.84863 19.5 4.5 16.1514 4.5 12C4.5 7.84863 7.84863 4.5 12 4.5C14.543 4.5 16.7812 5.75977 18.1406 7.6875L19.3594 6.82031C17.7305 4.50879 15.0381 3 12 3ZM17.5078 8.46094L16.4297 9.53906L18.1406 11.25H9V12.75H18.1406L16.4297 14.4609L17.5078 15.5391L20.5078 12.5391L21.0234 12L20.5078 11.4609L17.5078 8.46094Z' />
        </SvgIcon>
    );
}
export default SignOutIcon;
