/* eslint-disable indent */
import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import {
    Box,
    Typography,
    Container,
    Button,
    Alert,
    Snackbar,
} from '@mui/material';
import AuthService from '../../api/auth.service';

function AccountPage() {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const navigate = useNavigate();
    const logout = () => {
        AuthService.logout().then(() => {
            setSnackBarSeverity('success');
            setSnackBarMessage('Вы вышли из приложения');
            setOpenSnackBar(true);
            setTimeout(() => {
                navigate('/sign-in');
            }, 2000);
        });
    };

    return (
        <>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Container
                maxWidth='100%'
                sx={{ backgroundColor: 'primary.white' }}
            >
                <Box
                    component='div'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginLeft: '-32px',
                        marginRight: '-32px',
                        flexWrap: 'wrap',
                        paddingTop: '32px',
                        paddingBottom: '32px',
                    }}
                >
                    <Box
                        container='div'
                        sx={{
                            paddingRight: '32px',
                            paddingLeft: '32px',
                        }}
                    >
                        <Typography component='h1' variant='h1'>
                            Настройки аккаунта
                        </Typography>
                    </Box>
                    <Box
                        container='div'
                        sx={{
                            paddingRight: '32px',
                            paddingLeft: '32px',
                        }}
                    >
                        <Box
                            component='div'
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Alert severity='warning'>
                                Ваш тариф: <strong>Премиум</strong>. До
                                окончания подписки осталось 15 дней
                            </Alert>
                            <Button
                                type='submit'
                                variant='contained'
                                size='large'
                                sx={{
                                    borderRadius: '26px',
                                    marginLeft: '16px',
                                }}
                            >
                                Оплатить доступ
                            </Button>
                            <Button
                                type='submit'
                                variant='contained'
                                size='large'
                                sx={{
                                    borderRadius: '26px',
                                    backgroundColor:
                                        'background.blueExtraLight',
                                    color: 'text.primary',
                                    marginLeft: '16px',
                                    '&:hover': {
                                        color: '#fff',
                                    },
                                }}
                                onClick={logout}
                            >
                                Выйти из аккаунта
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Container maxWidth='100%' sx={{ backgroundColor: 'background' }}>
                <Outlet />
            </Container>
        </>
    );
}

export default AccountPage;
