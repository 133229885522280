import {
    AppBar,
    Toolbar,
    Box,
    Typography,
    IconButton,
    Badge,
    Link,
    Tooltip,
    Menu,
    MenuItem,
    Button,
    Container,
    Alert,
    Snackbar,
} from '@mui/material';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import QuestionIcon from '../icons/QuestionIcon';
import BellIcon from '../icons/BellIcon';
import UserIcon from '../icons/UserIcon';
import PaymentStoryIcon from '../icons/PaymentStoryIcon';
import TariffsIcon from '../icons/TariffsIcon';
import SignOutIcon from '../icons/SignOutIcon';
import KeyIcon from '../icons/KeyIcon';
import AuthService from '../../api/auth.service';

function Header() {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const navigate = useNavigate();

    const logout = () => {
        AuthService.logout().then(() => {
            setSnackBarSeverity('success');
            setSnackBarMessage('Вы вышли из приложения');
            setOpenSnackBar(true);
            setTimeout(() => {
                navigate('/sign-in');
            }, 2000);
        });
    };

    const [userData, setUserData] = useState('');

    const getUserData = () => {
        AuthService.getUserData().then(
            (response) => {
                setUserData(response.data);
            },
            (error) => {
                const errorMsg =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setSnackBarSeverity('error');
                setSnackBarMessage(errorMsg);
                setOpenSnackBar(true);
                if (
                    error.response.status === 403 ||
                    error.response.status === 500
                ) {
                    setTimeout(() => {
                        logout();
                    }, 2000);
                }
            }
        );
    };

    useEffect(() => {
        document.title = 'Настройки аккаунта';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница настроек аккаунта');
        getUserData();
    }, []);

    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSignOut = () => {
        logout();
    };

    return (
        <>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <AppBar
                component={Container}
                maxWidth='100%'
                position='sticky'
                color='inherit'
                sx={{ backgroundColor: '#ffffff' }}
            >
                <Toolbar
                    sx={{
                        padding: '0',
                        height: 4,
                        minHeight: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    disableGutters
                >
                    <Box
                        component='div'
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        {userData.company && (
                            <Typography
                                component='div'
                                variant='body2'
                                sx={{ fontWeight: '600' }}
                            >
                                AnyCoding
                            </Typography>
                        )}
                        <Typography
                            component='div'
                            variant='body2'
                            sx={{ marginLeft: 3 }}
                        >
                            4 545 454.00 ₽
                        </Typography>
                    </Box>
                    <Box
                        component='div'
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <IconButton
                            aria-label='справка'
                            onClick={() => {
                                alert('справка');
                            }}
                        >
                            <QuestionIcon />
                        </IconButton>
                        <IconButton
                            aria-label='уведомления'
                            onClick={() => {
                                alert('уведомления');
                            }}
                        >
                            <Badge color='error' variant='dot'>
                                <BellIcon />
                            </Badge>
                        </IconButton>
                        <Box
                            component='div'
                            sx={{
                                marginLeft: '24px',
                            }}
                        >
                            <Tooltip title='Настройки аккаунта'>
                                <Button
                                    onClick={handleOpenUserMenu}
                                    sx={{
                                        flexFlow: 'column',
                                        alignItems: 'flex-end',
                                        px: '16px',
                                    }}
                                >
                                    <Typography
                                        component='div'
                                        variant='body2'
                                        sx={{
                                            textAlign: 'right',
                                            fontWeight: '600',
                                            lineHeight: '18px',
                                            color: 'text.primary',
                                        }}
                                    >
                                        {userData.name}
                                    </Typography>
                                    {userData.company && (
                                        <Typography
                                            component='div'
                                            sx={{
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                lineHeight: '14px',
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {userData.company}
                                        </Typography>
                                    )}
                                </Button>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id='menu-appbar'
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Link
                                        component={ReactLink}
                                        to='/profile'
                                        underline='none'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <UserIcon
                                            sx={{
                                                fill: '#111827',
                                                marginRight: '4px',
                                            }}
                                        />
                                        <Typography
                                            component='div'
                                            variant='body2'
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Настройки аккаунта
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Link
                                        component={ReactLink}
                                        to='/profile/change-password'
                                        underline='none'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <KeyIcon
                                            sx={{
                                                fill: '#111827',
                                                marginRight: '4px',
                                            }}
                                        />
                                        <Typography
                                            component='div'
                                            variant='body2'
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Изменить пароль
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Link
                                        component={ReactLink}
                                        to='/profile/tariffs'
                                        underline='none'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TariffsIcon
                                            sx={{
                                                fill: '#111827',
                                                marginRight: '4px',
                                            }}
                                        />
                                        <Typography
                                            component='div'
                                            variant='body2'
                                            sx={{ color: 'text.primary' }}
                                        >
                                            Тарифы и оплата
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <Link
                                        component={ReactLink}
                                        to='/profile/payment-story'
                                        underline='none'
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <PaymentStoryIcon
                                            sx={{
                                                fill: '#111827',
                                                marginRight: '4px',
                                            }}
                                        />
                                        <Typography
                                            component='div'
                                            variant='body2'
                                            sx={{ color: 'text.primary' }}
                                        >
                                            История платежей
                                        </Typography>
                                    </Link>
                                </MenuItem>
                                <MenuItem onClick={handleSignOut}>
                                    <SignOutIcon
                                        sx={{
                                            fill: '#111827',
                                            marginRight: '4px',
                                        }}
                                    />
                                    <Typography component='div' variant='body2'>
                                        Выйти
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Header;
