import SvgIcon from '@mui/material/SvgIcon';

function FileImportIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M4.5 3V21H19.5V14.25H18V19.5H6V4.5H18V9.75H19.5V3H4.5ZM13.125 8.25L9.89062 11.4609L9.375 12L9.89062 12.5391L13.125 15.75L14.1797 14.6953L12.2344 12.75H21V11.25H12.2344L14.1797 9.30469L13.125 8.25Z' />
        </SvgIcon>
    );
}
export default FileImportIcon;
