import { Outlet, Navigate } from 'react-router-dom';
import AuthService from '../api/auth.service';

function IsAuth() {
    const auth = AuthService.isAuth();

    if (auth) {
        return <Navigate to={-1} replace />;
    }
    return <Outlet />;
}

export default IsAuth;
