import { Box, Link, Typography, IconButton } from '@mui/material';
import LogoIcon from '../../icons/LogoIcon';
import CloseIcon from '../../icons/CloseIcon';

function SidebarHeader() {
    return (
        <Box
            component='div'
            sx={{
                height: '64px',
                flex: '0 0 64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: '20px',
            }}
        >
            <Link
                href='/'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <LogoIcon sx={{ width: '32px', height: '32px' }} />
                <Typography
                    component='span'
                    variant='body'
                    sx={{
                        color: '#ffffff',
                        fontWeight: '600',
                        marginLeft: 1,
                    }}
                >
                    Standfin
                </Typography>
            </Link>
            <IconButton
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                }}
            >
                <CloseIcon sx={{ fill: '#ffffff' }} />
            </IconButton>
        </Box>
    );
}

export default SidebarHeader;
