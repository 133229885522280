import SvgIcon from '@mui/material/SvgIcon';

function PaymentStoryIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 1.5C11.055 1.5 10.3876 2.1675 10.0576 3H3.75V21.75H20.25V3H13.9424C13.6124 2.1675 12.945 1.5 12 1.5ZM12 3C12.4125 3 12.75 3.3375 12.75 3.75V4.5H15V6H9V4.5H11.25V3.75C11.25 3.3375 11.5875 3 12 3ZM5.25 4.5H7.5V7.5H16.5V4.5H18.75V20.25H5.25V4.5ZM6.75 9.75V11.25H8.25V9.75H6.75ZM9.75 9.75V11.25H17.25V9.75H9.75ZM6.75 12.75V14.25H8.25V12.75H6.75ZM9.75 12.75V14.25H17.25V12.75H9.75ZM6.75 15.75V17.25H8.25V15.75H6.75ZM9.75 15.75V17.25H17.25V15.75H9.75Z' />
        </SvgIcon>
    );
}
export default PaymentStoryIcon;
