import SvgIcon from '@mui/material/SvgIcon';

function AddressBookIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M4.5 3V6.75H3.75V8.25H6V4.5H18V19.5H6V17.25H4.5V21H19.5V3H4.5ZM12 7.5C10.3506 7.5 9 8.85059 9 10.5C9 11.335 9.35742 12.0879 9.91406 12.6328C8.91504 13.3096 8.25 14.4639 8.25 15.75H9.75C9.75 14.5078 10.7578 13.5 12 13.5C13.2422 13.5 14.25 14.5078 14.25 15.75H15.75C15.75 14.4639 15.085 13.3096 14.0859 12.6328C14.6426 12.0879 15 11.335 15 10.5C15 8.85059 13.6494 7.5 12 7.5ZM4.5 9V10.5H3.75V12H6V9H4.5ZM12 9C12.8379 9 13.5 9.66211 13.5 10.5C13.5 11.3379 12.8379 12 12 12C11.1621 12 10.5 11.3379 10.5 10.5C10.5 9.66211 11.1621 9 12 9ZM4.5 12.75V14.25H3.75V15.75H6V12.75H4.5Z' />
        </SvgIcon>
    );
}
export default AddressBookIcon;
