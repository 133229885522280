// import { capitalize } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import fonts from '../fonts/fonts';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1920,
        },
    },
    palette: {
        type: 'light',
        primary: {
            main: '#564ae0',
            light: '#818cf8',
            white: '#ffffff',
        },
        secondary: {
            main: '#ef5350',
        },
        background: {
            blueExtraLight: '#E2E8F0',
            default: '#F1F5F9',
        },
        text: {
            primary: '#111827',
            secondary: 'rgba(17,24,39,0.54)',
            disabled: 'rgba(17,24,39,0.35)',
            hint: 'rgba(17,24,39,0.4)',
        },
    },
    typography: {
        fontFamily: ['"Inter"', 'Roboto', 'Arial', 'sans-serif'].join(','),
        fontSize: 16,
        htmlFontSize: 16,
        fontWeightBold: 700,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
        h1: {
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: 1.167,
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 700,
            lineHeight: 1.3,
        },
        h3: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: 1.5,
        },
        h4: {
            fontSize: '0.9rem',
            fontWeight: 700,
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '0.8rem',
            fontWeight: 700,
            lineHeight: 1.5,
        },
        h6: {
            fontSize: '0.7rem',
            fontWeight: 700,
            lineHeight: 1.5,
        },
        subtitle1: {
            fontSize: '1.4rem',
            fontWeight: 400,
            lineHeight: 1.75,
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: 1.57,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 1.42857,
        },
        button: {
            fontWeight: 600,
            fontSize: '0.875rem',
            lineHeight: 1.75,
            textTransform: 'none',
        },
        caption: {
            fontSize: '0.9rem',
            fontWeight: 400,
            lineHeight: 1.66,
        },
        overline: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: 2.66,
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 1536px)': {
                        paddingLeft: '32px',
                        paddingRight: '32px',
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: fonts,
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '0.875rem',
                    marginLeft: '-4px',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: '22px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    height: '40px',
                    backgroundColor: '#ffffff',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E2E8F0',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: '600',
                    backgroundColor: '#E2E8F0',
                },
            },
        },
    },
});

export default theme;
