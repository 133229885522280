import { Paper, Box } from '@mui/material';
import SidebarHeader from './header/SidebarHeader';
import SidebarMenu from './menu/SidebarMenu';
import SidebarFooter from './footer/SidebarFooter';

function Sidebar() {
    return (
        <Paper
            component='aside'
            elevation={4}
            square
            sx={{
                height: '100vh',
                width: '280px',
                backgroundColor: 'text.primary',
            }}
        >
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    height: '100%',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <SidebarHeader />
                <SidebarMenu />
                <SidebarFooter />
            </Box>
        </Paper>
    );
}

export default Sidebar;
