import SvgIcon from '@mui/material/SvgIcon';

function BalanceIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 3.75C11.0303 3.75 10.2041 4.38281 9.89062 5.25H4.5V6.75H5.55469L5.34375 7.125L2.34375 12.375L2.25 12.5625V12.75C2.25 14.8125 3.9375 16.5 6 16.5C8.0625 16.5 9.75 14.8125 9.75 12.75V12.5625L9.65625 12.375L6.65625 7.125L6.44531 6.75H9.89062C10.1191 7.38281 10.6172 7.88086 11.25 8.10938V18H8.25V19.5H15.75V18H12.75V8.10938C13.3799 7.88086 13.8809 7.37988 14.1094 6.75H17.5547L17.3438 7.125L14.3438 12.375L14.25 12.5625V12.75C14.25 14.8125 15.9375 16.5 18 16.5C20.0625 16.5 21.75 14.8125 21.75 12.75V12.5625L21.6562 12.375L18.6562 7.125L18.4453 6.75H19.5V5.25H14.1094C13.7959 4.38281 12.9697 3.75 12 3.75ZM12 5.25C12.4219 5.25 12.75 5.57812 12.75 6C12.75 6.42188 12.4219 6.75 12 6.75C11.5781 6.75 11.25 6.42188 11.25 6C11.25 5.57812 11.5781 5.25 12 5.25ZM6 9.02344L7.71094 12H4.28906L6 9.02344ZM18 9.02344L19.7109 12H16.2891L18 9.02344ZM3.9375 13.5H8.0625C7.76074 14.3701 6.97852 15 6 15C5.02148 15 4.23926 14.3701 3.9375 13.5ZM15.9375 13.5H20.0625C19.7607 14.3701 18.9785 15 18 15C17.0215 15 16.2393 14.3701 15.9375 13.5Z' />
        </SvgIcon>
    );
}
export default BalanceIcon;
