/* eslint-disable react/jsx-curly-brace-presence */
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import {
    Button,
    TextField,
    Link,
    Box,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LogoIcon from '../../components/icons/LogoIcon';
import AuthService from '../../api/auth.service';

function ResetPasswordPage() {
    const [token, setToken] = useState();
    const [email, setEmail] = useState();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const { resetEmail, resetToken } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Смена пароля';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница смены пароля');
        if (resetToken && resetEmail) {
            // console.log(resetToken);
            // console.log(resetEmail);
            // setToken(resetToken);
            // setEmail(resetEmail);
            AuthService.checkResetToken({
                token: resetToken,
                email: resetEmail,
            }).then(
                (response) => {
                    setToken(resetToken);
                    setEmail(resetEmail);
                    setSnackBarSeverity('success');
                    if (!response.message) {
                        setSnackBarMessage('Введите новый пароль');
                    } else {
                        setSnackBarMessage(response.message);
                    }
                    setOpenSnackBar(true);
                },
                (error) => {
                    const errorMsg =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => {
                        navigate('/restore-password', { replace: true });
                    }, 3000);
                    setSnackBarSeverity('error');
                    setSnackBarMessage(errorMsg);
                    setOpenSnackBar(true);
                }
            );
        } else {
            setTimeout(() => {
                navigate('/restore-password', { replace: true });
            }, 3000);
            setSnackBarSeverity('error');
            setSnackBarMessage(
                'Некорректная ссылка на восстановление! Повторите запрос на сброс пароля'
            );
            setOpenSnackBar(true);
        }
    }, []);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Обязательное поле')
            .min(8, 'Минимум 8 символов')
            .max(40, 'Максимум 40 символов'),
        confirmPassword: Yup.string()
            .required('Обязательное поле')
            .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        const values = {
            token,
            email,
            password: data.password,
            password_confirmation: data.confirmPassword,
        };

        AuthService.resetPasswordViaEmail(values).then(
            (response) => {
                setSnackBarSeverity('success');
                setSnackBarMessage(response.message);
                setOpenSnackBar(true);
                setTimeout(() => {
                    navigate('/profile');
                }, 3000);
            },
            (error) => {
                const errorMsg =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setSnackBarSeverity('error');
                setSnackBarMessage(errorMsg);
                setOpenSnackBar(true);
            }
        );
    };

    return (
        <>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '320px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    <LogoIcon
                        sx={{
                            width: 32,
                            height: 32,
                            marginBottom: 4,
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h1'
                        sx={{ marginBottom: 1 }}
                    >
                        Смена пароля
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Вспомнили пароль?
                        <Link
                            component={RouterLink}
                            to='/sign-in'
                            variant='body2'
                            sx={{ paddingLeft: 0.5 }}
                        >
                            Войти
                        </Link>
                    </Typography>
                </Box>
                <Box
                    component='form'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='От 8 до 40 символов'
                        name='password'
                        label='Пароль'
                        type='password'
                        id='password'
                        autoComplete='off'
                        {...register('password')}
                        error={!!errors.password}
                        helperText={
                            errors?.password ? errors.password.message : null
                        }
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='Повторите пароль'
                        name='confirmPassword'
                        label='Повтор пароля'
                        type='password'
                        id='confirmPassword'
                        autoComplete='off'
                        {...register('confirmPassword')}
                        error={!!errors.confirmPassword}
                        helperText={
                            errors?.confirmPassword
                                ? errors.confirmPassword.message
                                : null
                        }
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        sx={{ mt: 2, mb: 2, borderRadius: 26 }}
                    >
                        Изменить пароль
                    </Button>
                </Box>
                <Typography
                    variant='body2'
                    sx={{ mt: 5 }}
                    color='text.secondary'
                    align='center'
                >
                    {'Copyright © '}
                    <Link component={RouterLink} to='/' color='inherit'>
                        Standfin
                    </Link>{' '}
                    {new Date().getFullYear()}.
                </Typography>
            </Box>
        </>
        // resetToken && (
        //     <Box
        //         sx={{
        //             width: {
        //                 xs: '100%',
        //                 md: '320px',
        //             },
        //             display: 'flex',
        //             flexDirection: 'column',
        //             alignItems: 'center',
        //         }}
        //     >
        //         <Box
        //             sx={{
        //                 alignSelf: 'flex-start',
        //             }}
        //         >
        //             <LogoIcon
        //                 sx={{
        //                     width: 32,
        //                     height: 32,
        //                     marginBottom: 4,
        //                 }}
        //             />
        //             <Typography
        //                 component='h1'
        //                 variant='h1'
        //                 sx={{ marginBottom: 1 }}
        //             >
        //                 Смена пароля
        //             </Typography>
        //             <Typography component='div' variant='body2'>
        //                 Вспомнили пароль?
        //                 <Link
        //                     component={RouterLink}
        //                     to='/sign-in'
        //                     variant='body2'
        //                     sx={{ paddingLeft: 0.5 }}
        //                 >
        //                     Войти
        //                 </Link>
        //             </Typography>
        //         </Box>
        //         <Box
        //             component='form'
        //             noValidate
        //             onSubmit={handleSubmit(onSubmit)}
        //             sx={{ mt: 1 }}
        //         >
        //             <TextField
        //                 margin='normal'
        //                 required
        //                 size='small'
        //                 fullWidth
        //                 placeholder='От 8 до 40 символов'
        //                 name='password'
        //                 label='Пароль'
        //                 type='password'
        //                 id='password'
        //                 autoComplete='off'
        //                 {...register('password')}
        //                 error={!!errors.password}
        //                 helperText={
        //                     errors?.password ? errors.password.message : null
        //                 }
        //             />
        //             <TextField
        //                 margin='normal'
        //                 required
        //                 size='small'
        //                 fullWidth
        //                 placeholder='Повторите пароль'
        //                 name='confirmPassword'
        //                 label='Повтор пароля'
        //                 type='password'
        //                 id='confirmPassword'
        //                 autoComplete='off'
        //                 {...register('confirmPassword')}
        //                 error={!!errors.confirmPassword}
        //                 helperText={
        //                     errors?.confirmPassword
        //                         ? errors.confirmPassword.message
        //                         : null
        //                 }
        //             />
        //             <Button
        //                 type='submit'
        //                 fullWidth
        //                 variant='contained'
        //                 size='large'
        //                 sx={{ mt: 2, mb: 2, borderRadius: 26 }}
        //             >
        //                 Изменить пароль
        //             </Button>
        //         </Box>
        //         <Typography
        //             variant='body2'
        //             sx={{ mt: 5 }}
        //             color='text.secondary'
        //             align='center'
        //         >
        //             {'Copyright © '}
        //             <Link component={RouterLink} to='/' color='inherit'>
        //                 Standfin
        //             </Link>{' '}
        //             {new Date().getFullYear()}.
        //         </Typography>
        //     </Box>
        // )
    );
}

export default ResetPasswordPage;
