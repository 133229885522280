import { Box, List } from '@mui/material';
import { useLocation } from 'react-router-dom';
import menuItems from '../../../mocks/menu_items';
import SidebarMenuItem from './SidebarMenuItem';

function SidebarMenu() {
    const location = useLocation().pathname;
    return (
        <Box
            sx={{
                marginTop: '64px',
                marginBottom: '40px',
                overflow: 'hidden auto',
                overscrollBehavior: 'contain',
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
                '&::-webkit-scrollbar': {
                    height: '8px',
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'rgba(255, 255, 255, 0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '5px',
                    border: '3px solid transparent',
                },
            }}
        >
            <List
                sx={{
                    width: '100%',
                    bgcolor: 'text.primary',
                    px: '12px',
                }}
                component='nav'
                aria-labelledby='nested-list-subheader'
            >
                <Box
                    component='ul'
                    sx={{ padding: '0', py: '12px', margin: '0' }}
                >
                    {menuItems.map((item) => (
                        <SidebarMenuItem
                            key={item.id}
                            item={item}
                            location={location}
                        />
                    ))}
                </Box>
            </List>
        </Box>
    );
}

export default SidebarMenu;
