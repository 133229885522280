import SvgIcon from '@mui/material/SvgIcon';

function QuestionIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 3C7.03711 3 3 7.03711 3 12C3 16.9629 7.03711 21 12 21C16.9629 21 21 16.9629 21 12C21 7.03711 16.9629 3 12 3ZM12 4.5C16.1514 4.5 19.5 7.84863 19.5 12C19.5 16.1514 16.1514 19.5 12 19.5C7.84863 19.5 4.5 16.1514 4.5 12C4.5 7.84863 7.84863 4.5 12 4.5ZM12 7.5C10.3506 7.5 9 8.85059 9 10.5H10.5C10.5 9.66211 11.1621 9 12 9C12.8379 9 13.5 9.66211 13.5 10.5C13.5 11.0742 13.1309 11.584 12.5859 11.7656L12.2812 11.8594C11.6689 12.0615 11.25 12.6475 11.25 13.2891V14.25H12.75V13.2891L13.0547 13.1953C14.209 12.8115 15 11.7158 15 10.5C15 8.85059 13.6494 7.5 12 7.5ZM11.25 15V16.5H12.75V15H11.25Z' />
        </SvgIcon>
    );
}
export default QuestionIcon;
