import SvgIcon from '@mui/material/SvgIcon';

function RetweetIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M3.75 5.25L0 9H3V18.75H15.75L14.25 17.25H4.5V9H7.5L3.75 5.25ZM6.75 5.25L8.25 6.75H18V15H15L18.75 18.75L22.5 15H19.5V5.25H6.75Z' />
        </SvgIcon>
    );
}
export default RetweetIcon;
