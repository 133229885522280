/* eslint-disable indent */
import { Link as ReactLink } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    Alert,
    Snackbar,
    TextField,
    Link,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthService from '../../api/auth.service';

function AccountChangePasswordPage() {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    useEffect(() => {
        document.title = 'Смена пароля';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница смены пароля');
    }, []);

    const changeUserPassword = (data) => {
        AuthService.changeUserPassword(
            data.oldPassword,
            data.newPassword,
            data.confirmNewPassword
        ).then(
            (response) => {
                setSnackBarSeverity('success');
                setSnackBarMessage(response.message);
                setOpenSnackBar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            },
            (error) => {
                const errorMsg =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setSnackBarSeverity('error');
                setSnackBarMessage(errorMsg);
                setOpenSnackBar(true);
            }
        );
    };

    const changePasswordValidationSchema = Yup.object({
        oldPassword: Yup.string()
            .required('Обязательное поле')
            .min(8, 'Минимум 8 символов')
            .max(40, 'Максимум 40 символов'),
        newPassword: Yup.string()
            .required('Обязательное поле')
            .min(8, 'Минимум 8 символов')
            .max(40, 'Максимум 40 символов'),
        confirmNewPassword: Yup.string()
            .required('Обязательное поле')
            .oneOf([Yup.ref('newPassword'), null], 'Пароли не совпадают'),
    });

    const formikChangePassword = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema: changePasswordValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            changeUserPassword(values);
        },
    });

    return (
        <>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: 'background.blueExtraLight',
                    backgroundColor: '#ffffff',
                    marginLeft: '-32px',
                    marginRight: '-32px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                }}
            >
                <Link
                    component={ReactLink}
                    to='/profile'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Основные настройки
                </Link>
                <Box
                    component='div'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.primary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        backgroundColor: '#F1F5F9',
                        borderRadius: '12px 12px 0 0',
                        border: '1px solid',
                        borderColor: 'background.blueExtraLight',
                        borderBottomColor: '#F1F5F9',
                        marginBottom: '-1px',
                    }}
                >
                    Смена пароля
                </Box>
                <Link
                    component={ReactLink}
                    to='/profile/tariffs'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Тарифы и оплата
                </Link>
                <Link
                    component={ReactLink}
                    to='/profile/payment-story'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    История платежей
                </Link>
            </Box>
            <Box
                component='div'
                sx={{
                    maxWidth: '400px',
                    paddingTop: '32px',
                    paddingBottom: '32px',
                }}
            >
                <Typography
                    component='div'
                    variant='h3'
                    sx={{ paddingLeft: '16px' }}
                >
                    Смена пароля
                </Typography>
                <Box
                    component='form'
                    noValidate
                    onSubmit={formikChangePassword.handleSubmit}
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='От 8 до 40 символов'
                        name='oldPassword'
                        label='Старый пароль'
                        type='password'
                        id='oldPassword'
                        autoComplete='off'
                        onChange={formikChangePassword.handleChange}
                        value={formikChangePassword.values.oldPassword}
                        error={!!formikChangePassword.errors.oldPassword}
                        helperText={
                            formikChangePassword.errors.oldPassword
                                ? formikChangePassword.errors.oldPassword
                                : null
                        }
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='От 8 до 40 символов'
                        name='newPassword'
                        label='Новый пароль'
                        type='password'
                        id='newPassword'
                        autoComplete='off'
                        onChange={formikChangePassword.handleChange}
                        value={formikChangePassword.values.newPassword}
                        error={!!formikChangePassword.errors.newPassword}
                        helperText={
                            formikChangePassword.errors.newPassword
                                ? formikChangePassword.errors.newPassword
                                : null
                        }
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='Повторите новый пароль'
                        name='confirmNewPassword'
                        label='Повтор пароля'
                        type='password'
                        id='confirmNewPassword'
                        autoComplete='off'
                        onChange={formikChangePassword.handleChange}
                        value={formikChangePassword.values.confirmNewPassword}
                        error={!!formikChangePassword.errors.confirmNewPassword}
                        helperText={
                            formikChangePassword.errors.confirmNewPassword
                                ? formikChangePassword.errors.confirmNewPassword
                                : null
                        }
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        sx={{ mt: 3, mb: 2, borderRadius: 26 }}
                    >
                        Сменить пароль
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default AccountChangePasswordPage;
