import SvgIcon from '@mui/material/SvgIcon';

function ChartLineIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M20.4141 4.03125L17.7891 7.3125L15.3281 6.07031L14.7422 5.78906L14.3672 6.32812L11.8125 10.1719L9.44531 8.39062L9 8.0625L8.55469 8.39062L5.83594 10.4297L3.1875 9.77344L2.8125 11.2266L5.8125 11.9766L6.16406 12.0703L6.44531 11.8594L9 9.9375L11.5547 11.8594L12.1875 12.3281L12.6328 11.6719L15.2578 7.73438L17.6719 8.92969L18.2109 9.1875L21.5859 4.96875L20.4141 4.03125ZM15.0469 13.7344L14.4141 14.5312L11.8594 17.7188L9.44531 15.8906L9 15.5625L8.55469 15.8906L5.92969 17.8594L3.32812 16.5703L2.67188 17.9297L5.67188 19.4297L6.07031 19.6172L6.44531 19.3594L9 17.4375L11.5547 19.3594L12.1406 19.7812L12.5859 19.2188L14.9297 16.2891L17.3672 19.9219L17.9531 20.7656L18.5859 19.9688L21.5859 16.2188L20.4141 15.2812L18.0703 18.2109L15.6328 14.5781L15.0469 13.7344Z' />
        </SvgIcon>
    );
}
export default ChartLineIcon;
