import { Outlet } from 'react-router-dom';
import { Grid, Box, Typography, Paper } from '@mui/material';
import BackgroundImage from './background.jpg';

function LayoutDefault() {
    return (
        <Grid container component='main' sx={{ height: '100vh' }}>
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                xl={6}
                component={Paper}
                elevation={6}
                square
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    padding: { xs: 2, sm: 3, md: 8 },
                }}
            >
                <Outlet />
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                xl={6}
                sx={{
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: {
                        xs: 'none',
                        sm: 'flex',
                    },
                }}
            >
                <Box
                    component='div'
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component='div'
                        sx={{ paddingLeft: '164px', maxWidth: '480px' }}
                    >
                        <Typography
                            component='h1'
                            variant='h1'
                            sx={{ color: '#ffffff' }}
                        >
                            Добро пожаловать в StandFin
                        </Typography>
                        <Typography
                            component='div'
                            variant='body2'
                            sx={{ color: '#BDBDBD', marginTop: 3 }}
                        >
                            StandFin&nbsp;&mdash; онлайн-сервис финансового
                            и&nbsp;управленческого учета для вашего бизнеса
                        </Typography>
                        <Typography
                            component='div'
                            variant='body2'
                            sx={{ color: '#BDBDBD', marginTop: 3 }}
                        >
                            Уже более 2&nbsp;500 пользователей с&nbsp;нами!
                            Присоединяйся и&nbsp;ты!
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default LayoutDefault;
