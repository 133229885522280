import SvgIcon from '@mui/material/SvgIcon';

function FileInvoiceDollarIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M4.5 2.25V21.75H16.5V20.25H6V3.75H13.5V8.25H18V9.75H19.5V7.19971L19.2744 6.97559L14.7744 2.47559L14.5503 2.25H4.5ZM15 4.80029L16.9497 6.75H15V4.80029ZM7.5 9.75V11.25H16.5V9.75H7.5ZM20.25 11.25V12.75C18.975 12.975 18 14.025 18 15.375C18 16.875 19.125 18 20.625 18H21.375C21.975 18 22.5 18.525 22.5 19.125C22.5 19.725 21.975 20.25 21.375 20.25H18.75V21.75H20.25V23.25H21.75V21.75C23.025 21.525 24 20.475 24 19.125C24 17.625 22.875 16.5 21.375 16.5H20.625C20.025 16.5 19.5 15.975 19.5 15.375C19.5 14.775 20.025 14.25 20.625 14.25H23.25V12.75H21.75V11.25H20.25ZM7.5 13.5V15H12.75V13.5H7.5ZM14.25 13.5V15H16.5V13.5H14.25ZM7.5 16.5V18H12.75V16.5H7.5ZM14.25 16.5V18H16.5V16.5H14.25Z' />
        </SvgIcon>
    );
}
export default FileInvoiceDollarIcon;
