import SvgIcon from '@mui/material/SvgIcon';

function LogoIcon(props) {
    return (
        <SvgIcon {...props}>
            <path
                d='M12.4503 5.06667V0L0.783804 10.9792C0.342685 11.3943 0.338705 12.0762 0.774952 12.4961L2.75309 14.4'
                fill='#2196F3'
            />
            <path
                d='M12.4503 5.06667V0L24.1169 10.9792C24.558 11.3943 24.562 12.0762 24.1258 12.4961L22.1476 14.4'
                fill='#1878C6'
            />
            <path
                d='M2.75309 14.4L7.46318 9.8667H12.4503V14.4'
                fill='#1565C0'
            />
            <path
                d='M22.1476 14.4L17.4375 9.8667H12.4503V14.4'
                fill='#10519D'
            />
            <path
                d='M12.4503 19.4667H7.74024L10.0953 17.0667L12.4503 19.4667Z'
                fill='#1565C0'
            />
            <path d='M12.4503 24L17.1604 19.4667H12.4503V24Z' fill='#1878C6' />
            <path d='M12.4503 24L7.74024 19.4667H12.4503V24Z' fill='#2196F3' />
            <path
                d='M12.4503 19.4667H17.1604L14.8054 17.0667L12.4503 19.4667Z'
                fill='#10519D'
            />
            <path
                d='M6.00103 16.3126C5.58213 16.7314 5.58864 17.3958 6.01568 17.8068L7.74024 19.4667L12.4503 14.6667L12.4503 9.8667'
                fill='#2196F3'
            />
            <path
                d='M18.8997 16.3126C19.3186 16.7314 19.3121 17.3958 18.885 17.8068L17.1604 19.4667L12.4503 14.6667L12.4503 9.8667'
                fill='#1878C6'
            />
        </SvgIcon>
    );
}
export default LogoIcon;
