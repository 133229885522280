import { useRoutes } from 'react-router-dom';
import RequireAuth from '../hoc/RequireAuth';
import IsAuth from '../hoc/IsAuth';
import LayoutDefault from '../components/layouts/LayoutDefault';
import LayoutFullPage from '../components/layouts/LayoutFullPage';
import NotFoundPage from '../pages/404/NotFoundPage';
import SignInPage from '../pages/sign-in/SignInPage';
import SignUpPage from '../pages/sign-up/SignUpPage';
import RestorePasswordPage from '../pages/restore-password/RestorePasswordPage';
import ResetPasswordPage from '../pages/reset-password/ResetPassword';
import AccountPage from '../pages/account/AccountPage';
import AccountDataPage from '../pages/account/AccountDataPage';
import AccountChangePasswordPage from '../pages/account/AccountChangePasswordPage';
import AccountTariffsPage from '../pages/account/AccountTariffsPage';
import AccountPaymentStoryPage from '../pages/account/AccountPaymentStoryPage';
import WelcomePage from '../pages/welcome/WelcomePage';

function Routes() {
    return useRoutes([
        {
            path: '/',
            element: <RequireAuth />,
            children: [
                {
                    path: '',
                    element: <LayoutDefault />,
                    children: [
                        {
                            path: '',
                            element: <WelcomePage />,
                        },
                    ],
                },
                {
                    path: 'dashboard',
                    element: <LayoutDefault />,
                    children: [
                        {
                            path: 'cash-flow',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'account-balances',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'income-structure',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'outcome-structure',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                    ],
                },
                {
                    path: 'reports',
                    element: <LayoutDefault />,
                    children: [
                        {
                            path: 'funds-flow',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'income-losses',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'income-structure',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'balance',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                    ],
                },
                {
                    path: 'cash-flow',
                    element: <LayoutDefault />,
                    children: [
                        {
                            path: 'transactions',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'import',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'rules',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'reconciliation',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                    ],
                },
                {
                    path: 'reference-book',
                    element: <LayoutDefault />,
                    children: [
                        {
                            path: 'articles',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'bills',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'directions',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'contractors',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                    ],
                },
                {
                    path: 'profile',
                    element: <LayoutDefault />,
                    children: [
                        {
                            path: '',
                            element: <AccountPage />,
                            children: [
                                {
                                    path: '',
                                    element: <AccountDataPage />,
                                },
                            ],
                        },
                        {
                            path: 'change-password',
                            element: <AccountPage />,
                            children: [
                                {
                                    path: '',
                                    element: <AccountChangePasswordPage />,
                                },
                            ],
                        },
                        {
                            path: 'tariffs',
                            element: <AccountPage />,
                            children: [
                                {
                                    path: '',
                                    element: <AccountTariffsPage />,
                                },
                            ],
                        },
                        {
                            path: 'payment-story',
                            element: <AccountPage />,
                            children: [
                                {
                                    path: '',
                                    element: <AccountPaymentStoryPage />,
                                },
                            ],
                        },
                        {
                            path: 'integrations',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                        {
                            path: 'users',
                            element: <NotFoundPage minHeight='auto' />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/',
            element: <IsAuth />,
            children: [
                {
                    path: 'sign-in',
                    element: <LayoutFullPage />,
                    children: [
                        {
                            path: '',
                            element: <SignInPage />,
                        },
                    ],
                },
                {
                    path: 'sign-up',
                    element: <LayoutFullPage />,
                    children: [
                        {
                            path: '',
                            element: <SignUpPage />,
                        },
                    ],
                },
                {
                    path: 'restore-password',
                    element: <LayoutFullPage />,
                    children: [
                        {
                            path: '',
                            element: <RestorePasswordPage />,
                        },
                    ],
                },
                {
                    path: 'reset-password',
                    element: <LayoutFullPage />,
                    children: [
                        {
                            path: '',
                            element: <ResetPasswordPage />,
                        },
                        {
                            path: ':resetEmail/:resetToken',
                            element: <ResetPasswordPage />,
                        },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <NotFoundPage minHeight='100vh' />,
        },
    ]);
}

export default Routes;
