/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import api from './api';
import TokenService from './token.service';

const register = (name, email, phone, terms, password, password_confirmation) =>
    api
        .post(`/user/signup`, {
            name,
            email,
            phone,
            terms,
            password,
            password_confirmation,
            vat: false,
            company: '',
        })
        .then((response) => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        });

const login = (email, password) =>
    api
        .post(`/auth/signin`, {
            email,
            password,
        })
        .then((response) => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        });

const logout = async () => {
    await api.post(`/auth/logout`);
    localStorage.removeItem('user');
};

const changeUserData = (name, email, phone, company, company_vat) =>
    api
        .post(`/user/me`, {
            name,
            email,
            phone,
            company,
            company_vat,
        })
        .then((response) => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        });
const changeUserPassword = (
    old_password,
    new_password,
    new_password_confirmation
) =>
    api
        .post(`/user/password-change`, {
            old_password,
            new_password,
            new_password_confirmation,
        })
        .then((response) => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        });

const restorePasswordViaEmail = (email) =>
    api.post(`/password/restore`, {
        email,
    });

const checkResetToken = (data) =>
    api.post(`/password/check-reset-token`, data).then((response) => {
        if (response.data.access_token) {
            TokenService.setUser(response.data);
        }
        return response.data;
    });

const resetPasswordViaEmail = (data) =>
    api.post(`/password/reset`, data).then((response) => {
        console.log(response.data);
        if (response.data.access_token) {
            TokenService.setUser(response.data);
        }
        return response.data;
    });

const getUserData = () => api.get('/user/me');

const isAuth = () => {
    const refreshToken = TokenService.getLocalRefreshToken();
    return !!refreshToken;
};

const AuthService = {
    register,
    login,
    logout,
    changeUserData,
    changeUserPassword,
    restorePasswordViaEmail,
    checkResetToken,
    resetPasswordViaEmail,
    getUserData,
    isAuth,
};

export default AuthService;
