import SvgIcon from '@mui/material/SvgIcon';

function ChartBarIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M15.75 3V21H21.75V3H15.75ZM17.25 4.5H20.25V19.5H17.25V4.5ZM2.25 7.5V21H8.25V7.5H2.25ZM3.75 9H6.75V19.5H3.75V9ZM9 12V21H15V12H9ZM10.5 13.5H13.5V19.5H10.5V13.5Z' />
        </SvgIcon>
    );
}
export default ChartBarIcon;
