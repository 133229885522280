import SvgIcon from '@mui/material/SvgIcon';

function RandomIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M17.25 2.25V5.25H13.8047L13.5938 5.625L10.875 10.4766L7.94531 5.25H3V6.75H7.05469L10.0078 12L7.05469 17.25H3V18.75H7.94531L14.6953 6.75H17.25V9.75L21 6L17.25 2.25ZM12.5859 13.5L11.7188 15.0469L13.8047 18.75H17.25V21.75L21 18L17.25 14.25V17.25H14.6953L12.5859 13.5Z' />
        </SvgIcon>
    );
}
export default RandomIcon;
