import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from '../sidebar/Sidebar';
import Header from '../header/Header';

function LayoutDefault() {
    return (
        <Box
            sx={{
                display: 'flex',
                flex: '1 1 auto',
                width: '100%',
            }}
        >
            <Sidebar />
            <Box
                component='main'
                sx={{
                    flex: '1 1 auto',
                    height: '100vh',
                    overflow: 'auto',
                    display: 'flex',
                    flexFlow: 'column',
                }}
            >
                <Header />
                <Outlet />
            </Box>
        </Box>
    );
}

export default LayoutDefault;
