import { Navigate, useLocation } from 'react-router-dom';

function RemoveTrailingSlash() {
    const location = useLocation();

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return (
            <Navigate
                replace
                to={{
                    pathname: location.pathname.replace(/\/+$/, ''),
                    search: location.search,
                }}
            />
        );
    }
    return null;
}

export default RemoveTrailingSlash;
