import SvgIcon from '@mui/material/SvgIcon';

function TariffsIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 2.25C11.0566 2.25 10.3857 2.91797 10.0547 3.75H4.5V21H19.5V3.75H13.9453C13.6143 2.91797 12.9434 2.25 12 2.25ZM12 3.75C12.416 3.75 12.75 4.08398 12.75 4.5V5.25H15V6.75H9V5.25H11.25V4.5C11.25 4.08398 11.584 3.75 12 3.75ZM6 5.25H7.5V8.25H16.5V5.25H18V19.5H6V5.25Z' />
            <path d='M10.4062 10.5V13.875H9.75V14.55H10.4062V15.225H9.75V15.9H10.4062V17.25H11.0625V15.9H12.7031V15.225H11.0625V14.55H13.0312C14.1143 14.55 15 13.639 15 12.525C15 11.411 14.1143 10.5 13.0312 10.5H10.4062ZM11.0625 11.175H13.0312C13.7593 11.175 14.3438 11.7762 14.3438 12.525C14.3438 13.2738 13.7593 13.875 13.0312 13.875H11.0625V11.175Z' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.1062 10.2H13.0312C14.2879 10.2 15.3 11.2533 15.3 12.525C15.3 13.7967 14.2879 14.85 13.0312 14.85H11.3625V14.925H13.0031V16.2H11.3625V17.55H10.1062V16.2H9.45V14.925H10.1062V14.85H9.45V13.575H10.1062V10.2ZM10.4062 10.5H13.0312C14.1143 10.5 15 11.411 15 12.525C15 13.639 14.1143 14.55 13.0312 14.55H11.0625V15.225H12.7031V15.9H11.0625V17.25H10.4062V15.9H9.75V15.225H10.4062V14.55H9.75V13.875H10.4062V10.5ZM13.0312 11.475H11.3625V13.575H13.0312C13.5857 13.575 14.0437 13.1162 14.0437 12.525C14.0437 11.9338 13.5857 11.475 13.0312 11.475ZM11.0625 11.175V13.875H13.0312C13.7593 13.875 14.3438 13.2738 14.3438 12.525C14.3438 11.7762 13.7593 11.175 13.0312 11.175H11.0625Z'
            />
        </SvgIcon>
    );
}
export default TariffsIcon;
