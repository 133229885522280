import SvgIcon from '@mui/material/SvgIcon';

function BookIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M6.75 3C5.5166 3 4.5 4.0166 4.5 5.25V18.75C4.5 19.9834 5.5166 21 6.75 21H19.5V3H6.75ZM6.75 4.5H18V16.5H6.75C6.48633 16.5 6.2373 16.5557 6 16.6406V5.25C6 4.8252 6.3252 4.5 6.75 4.5ZM8.25 6.75V8.25H16.5V6.75H8.25ZM6.75 18H18V19.5H6.75C6.3252 19.5 6 19.1748 6 18.75C6 18.3252 6.3252 18 6.75 18Z' />
        </SvgIcon>
    );
}
export default BookIcon;
