/* eslint-disable indent */
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Button,
    Alert,
    Snackbar,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Link,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthService from '../../api/auth.service';

function AccountDataPage() {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const navigate = useNavigate();

    const logout = () => {
        AuthService.logout().then(() => {
            setSnackBarSeverity('success');
            setSnackBarMessage('Вы вышли из приложения');
            setOpenSnackBar(true);
            setTimeout(() => {
                navigate('/sign-in');
            }, 2000);
        });
    };

    const [userData, setUserData] = useState('');
    const getUserData = () => {
        AuthService.getUserData().then(
            (response) => {
                setUserData(response.data);
            },
            (error) => {
                const errorMsg =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setSnackBarSeverity('error');
                setSnackBarMessage(errorMsg);
                setOpenSnackBar(true);
                if (
                    error.response.status === 403 ||
                    error.response.status === 500
                ) {
                    setTimeout(() => {
                        logout();
                    }, 2000);
                }
            }
        );
    };
    useEffect(() => {
        document.title = 'Настройки аккаунта';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница настроек аккаунта');
        getUserData();
    }, []);

    const changeUserData = (data) => {
        AuthService.changeUserData(
            data.name,
            data.email,
            data.phone,
            data.company,
            data.company_vat
        ).then(
            (response) => {
                setSnackBarSeverity('success');
                setSnackBarMessage(response.message);
                setOpenSnackBar(true);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                const errorMessageEmail = JSON.stringify(
                    error.response.data.errors.email
                );

                let ermasg;

                if (errorMessageEmail) {
                    ermasg = `${resMessage}: Email уже занят`;
                } else {
                    ermasg = resMessage;
                }

                setSnackBarSeverity('error');
                setSnackBarMessage(ermasg);
                setOpenSnackBar(true);
            }
        );
    };

    const userDataValidationSchema = Yup.object({
        name: Yup.string()
            .required('Обязательное поле')
            .min(3, 'Минимум 3 символа')
            .max(60, 'Максимум 60 символов'),
        email: Yup.string()
            .required('Обязательное поле')
            .email('Некорректный email'),
        phone: Yup.string()
            .required('Обязательное поле')
            .min(10, 'Не хватает цифр в номере'),
        company: Yup.string().max(60, 'Максимум 60 символов').nullable(true),
        company_vat: Yup.bool(),
    });

    const formikUserData = useFormik({
        initialValues: {
            name: userData.name ? userData.name : '',
            email: userData.email ? userData.email : '',
            phone: userData.phone ? userData.phone : '',
            company: userData.company ? userData.company : '',
            company_vat: userData.company_vat ? userData.company_vat : false,
        },
        validationSchema: userDataValidationSchema,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            const data = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                company: values.company,
                company_vat: values.company_vat === 'true',
            };
            changeUserData(data);
        },
    });

    return (
        <>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: 'background.blueExtraLight',
                    backgroundColor: '#ffffff',
                    marginLeft: '-32px',
                    marginRight: '-32px',
                    paddingLeft: '32px',
                    paddingRight: '32px',
                }}
            >
                <Box
                    component='div'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.primary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        backgroundColor: '#F1F5F9',
                        borderRadius: '12px 12px 0 0',
                        border: '1px solid',
                        borderColor: 'background.blueExtraLight',
                        borderBottomColor: '#F1F5F9',
                        marginBottom: '-1px',
                    }}
                >
                    Основные настройки
                </Box>
                <Link
                    component={ReactLink}
                    to='/profile/change-password'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Смена пароля
                </Link>
                <Link
                    component={ReactLink}
                    to='/profile/tariffs'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    Тарифы и оплата
                </Link>
                <Link
                    component={ReactLink}
                    to='/profile/payment-story'
                    underline='none'
                    sx={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        fontWeight: '600',
                        color: 'text.secondary',
                        textAlign: 'center',
                        padding: '8px 16px',
                        borderRadius: '12px 12px 0 0',
                        marginBottom: '-1px',
                    }}
                >
                    История платежей
                </Link>
            </Box>
            <Box
                component='div'
                sx={{
                    maxWidth: '400px',
                    paddingTop: '32px',
                    paddingBottom: '32px',
                }}
            >
                <Typography
                    component='div'
                    variant='h3'
                    sx={{ paddingLeft: '16px' }}
                >
                    Персональные данные
                </Typography>
                <Box
                    component='form'
                    noValidate
                    onSubmit={formikUserData.handleSubmit}
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='name'
                        placeholder='Например, Иванов Иван'
                        label='Ваше имя'
                        name='name'
                        autoComplete='off'
                        onChange={formikUserData.handleChange}
                        value={formikUserData.values.name}
                        error={!!formikUserData.errors.name}
                        helperText={
                            formikUserData.errors.name
                                ? formikUserData.errors.name
                                : null
                        }
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='email'
                        placeholder='Например, ivan@gmail.com'
                        label='Email'
                        name='email'
                        autoComplete='off'
                        onChange={formikUserData.handleChange}
                        value={formikUserData.values.email}
                        error={!!formikUserData.errors.email}
                        helperText={
                            formikUserData.errors.email
                                ? formikUserData.errors.email
                                : null
                        }
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='phone'
                        placeholder='В формате +7 (999) 999-99-99'
                        label='Телефон'
                        name='phone'
                        autoComplete='off'
                        onChange={formikUserData.handleChange}
                        value={formikUserData.values.phone}
                        error={!!formikUserData.errors.phone}
                        helperText={
                            formikUserData.errors.phone
                                ? formikUserData.errors.phone
                                : null
                        }
                    />
                    <TextField
                        margin='normal'
                        size='small'
                        fullWidth
                        placeholder='От 3 до 60 символов'
                        name='company'
                        label='Название бизнеса'
                        type='text'
                        id='company'
                        autoComplete='off'
                        onChange={formikUserData.handleChange}
                        value={formikUserData.values.company}
                        error={!!formikUserData.errors.company}
                        helperText={
                            formikUserData.errors.company
                                ? formikUserData.errors.company
                                : null
                        }
                    />
                    <FormControl>
                        <FormLabel id='company_vat' sx={{ marginTop: '16px' }}>
                            Включить учет НДС (20%)
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby='Учитывать НДС в рассчетах?'
                            value={formikUserData.values.company_vat}
                            // defaultValue={formikUserData.values.vat}
                            onChange={formikUserData.handleChange}
                            name='company_vat'
                        >
                            <FormControlLabel
                                value='true'
                                control={<Radio />}
                                label='Да'
                            />
                            <FormControlLabel
                                value='false'
                                control={<Radio />}
                                label='Нет'
                            />
                        </RadioGroup>
                    </FormControl>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        sx={{ mt: 3, mb: 2, borderRadius: 26 }}
                    >
                        Сохранить
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default AccountDataPage;
