import { v4 as uuid } from 'uuid';
import ChartBarIcon from '../components/icons/ChartBarIcon';
import CoinsIcon from '../components/icons/CoinsIcon';
import LevelUpIcon from '../components/icons/LevelUpIcon';
import LevelDownIcon from '../components/icons/LevelDownIcon';
import ChartLineIcon from '../components/icons/ChartLineIcon';
import ChartPieIcon from '../components/icons/ChartPieIcon';
import BalanceIcon from '../components/icons/BalanceIcon';
import WalletIcon from '../components/icons/WalletIcon';
import FileImportIcon from '../components/icons/FileImportIcon';
import RandomIcon from '../components/icons/RandomIcon';
import SyncIcon from '../components/icons/SyncIcon';
import BookIcon from '../components/icons/BookIcon';
import FileInvoiceDollarIcon from '../components/icons/FileInvoiceDollarIcon';
import BriefcaseIcon from '../components/icons/BriefcaseIcon';
import AddressBookIcon from '../components/icons/AddressBookIcon';
import UserIcon from '../components/icons/UserIcon';
import RetweetIcon from '../components/icons/RetweetIcon';
import UserCogIcon from '../components/icons/UserCogIcon';

const menuItems = [
    {
        id: uuid(),
        name: 'Приборная панель',
        children: [
            {
                id: uuid(),
                name: 'Денежный поток',
                link: '/dashboard/cash-flow',
                icon: (
                    <ChartBarIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />
                ),
            },
            {
                id: uuid(),
                name: 'Остатки на счетах',
                link: '/dashboard/account-balances',
                icon: <CoinsIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Структура поступлений',
                link: '/dashboard/income-structure',
                icon: <LevelUpIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Структура списаний',
                link: '/dashboard/outcome-structure',
                icon: (
                    <LevelDownIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />
                ),
            },
        ],
    },
    {
        id: uuid(),
        name: 'Отчеты',
        children: [
            {
                id: uuid(),
                name: 'ОДДС',
                link: '/reports/funds-flow',
                icon: (
                    <ChartLineIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />
                ),
            },
            {
                id: uuid(),
                name: 'ОПИУ',
                link: '/reports/income-losses',
                icon: (
                    <ChartPieIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />
                ),
            },
            {
                id: uuid(),
                name: 'Балас',
                link: '/reports/balance',
                icon: <BalanceIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
        ],
    },
    {
        id: uuid(),
        name: 'Движение денег',
        children: [
            {
                id: uuid(),
                name: 'Транзакции',
                link: '/cash-flow/transactions',
                icon: <WalletIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Импорт',
                link: '/cash-flow/import',
                icon: (
                    <FileImportIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />
                ),
            },
            {
                id: uuid(),
                name: 'Правила',
                link: '/cash-flow/rules',
                icon: <RandomIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Сверка',
                link: '/cash-flow/reconciliation',
                icon: <SyncIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
        ],
    },
    {
        id: uuid(),
        name: 'Справочники',
        children: [
            {
                id: uuid(),
                name: 'Статьи',
                link: '/reference-book/articles',
                icon: <BookIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Счета',
                link: '/reference-book/bills',
                icon: (
                    <FileInvoiceDollarIcon
                        sx={{ fill: 'rgba(255, 255, 255, 0.7)' }}
                    />
                ),
            },
            {
                id: uuid(),
                name: 'Направления',
                link: '/reference-book/directions',
                icon: (
                    <BriefcaseIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />
                ),
            },
            {
                id: uuid(),
                name: 'Контрагенты',
                link: '/reference-book/contractors',
                icon: (
                    <AddressBookIcon
                        sx={{ fill: 'rgba(255, 255, 255, 0.7)' }}
                    />
                ),
            },
        ],
    },
    {
        id: uuid(),
        name: 'Настройки',
        children: [
            {
                id: uuid(),
                name: 'Личный кабинет',
                link: '/profile',
                icon: <UserIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Интеграции',
                link: '/profile/integrations',
                icon: <RetweetIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
            {
                id: uuid(),
                name: 'Пользователи',
                link: '/profile/users',
                icon: <UserCogIcon sx={{ fill: 'rgba(255, 255, 255, 0.7)' }} />,
            },
        ],
    },
];

export default menuItems;
