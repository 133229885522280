import SvgIcon from '@mui/material/SvgIcon';

function KeyIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M15 2.25C11.291 2.25 8.25 5.29102 8.25 9C8.25 9.38965 8.31445 9.73242 8.36719 10.0781L2.46094 15.9609L2.25 16.1953V21.75H7.5V19.5H9.75V17.25H12V15.0234C12.8848 15.457 13.916 15.75 15 15.75C18.709 15.75 21.75 12.709 21.75 9C21.75 5.29102 18.709 2.25 15 2.25ZM15 3.75C17.8916 3.75 20.25 6.1084 20.25 9C20.25 11.8916 17.8916 14.25 15 14.25C14.0918 14.25 13.1572 13.9834 12.4453 13.5938L12.2578 13.5H10.5V15.75H8.25V18H6V20.25H3.75V16.8281L9.67969 10.875L9.96094 10.6172L9.89062 10.2188C9.81445 9.76758 9.75 9.36621 9.75 9C9.75 6.1084 12.1084 3.75 15 3.75ZM16.5 6C15.6709 6 15 6.6709 15 7.5C15 8.3291 15.6709 9 16.5 9C17.3291 9 18 8.3291 18 7.5C18 6.6709 17.3291 6 16.5 6Z' />
        </SvgIcon>
    );
}
export default KeyIcon;
