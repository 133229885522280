const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.access_token;
};

const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.access_token;
};

const updateLocalAccessToken = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    setUser,
};

export default TokenService;
