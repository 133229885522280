import SvgIcon from '@mui/material/SvgIcon';

function WalletIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M17.2266 3C17.1123 3.00293 16.9922 3.01758 16.875 3.04687L4.6875 6.25781C3.70312 6.51562 3 7.41797 3 8.4375V18.75C3 19.9834 4.0166 21 5.25 21H18.75C19.9834 21 21 19.9834 21 18.75V9C21 7.7666 19.9834 6.75 18.75 6.75H8.71875L17.25 4.5V6H18.75V4.5C18.75 3.65625 18.0322 2.98828 17.2266 3ZM5.25 8.25H18.75C19.1748 8.25 19.5 8.57519 19.5 9V18.75C19.5 19.1748 19.1748 19.5 18.75 19.5H5.25C4.8252 19.5 4.5 19.1748 4.5 18.75V9C4.5 8.57519 4.8252 8.25 5.25 8.25ZM16.875 12.75C16.2539 12.75 15.75 13.2539 15.75 13.875C15.75 14.4961 16.2539 15 16.875 15C17.4961 15 18 14.4961 18 13.875C18 13.2539 17.4961 12.75 16.875 12.75Z' />
        </SvgIcon>
    );
}
export default WalletIcon;
