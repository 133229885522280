import { useLocation, Outlet, Navigate } from 'react-router-dom';
import AuthService from '../api/auth.service';

function RequireAuth() {
    const location = useLocation();
    const auth = AuthService.isAuth();

    if (!auth) {
        return <Navigate to='/sign-in' state={{ from: location }} />;
    }
    return <Outlet />;
}

export default RequireAuth;
