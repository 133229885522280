/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import axios from 'axios';
import TokenService from './token.service';
import AuthService from './auth.service';

const instance = axios.create({
    // baseURL: 'http://localhost:3033/api',
    baseURL: 'https://api.standfin.app.anycoding.ru/api',
    headers: {
        'Content-Type': 'application/json',
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; // for Spring Boot back-end
            // config.headers['x-access-token'] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => Promise.reject(error)
);
instance.interceptors.response.use(
    (res) => res,
    async (err) => {
        const originalConfig = err.config;
        if (
            originalConfig.url !== '/auth/signin' &&
            originalConfig.url !== '/auth/signup' &&
            originalConfig.url !== '/password/restore' &&
            originalConfig.url !== '/password/check-reset-token' &&
            originalConfig.url !== '/password/reset' &&
            originalConfig.url !== '/auth/logout' &&
            err.response
        ) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post('/auth/refresh', {
                        refreshToken: TokenService.getLocalRefreshToken(),
                    });
                    TokenService.updateLocalAccessToken(rs.data);
                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            } else if (err.response.status === 403) {
                AuthService.logout();
            }
        }
        return Promise.reject(err);
    }
);
export default instance;
