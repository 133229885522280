import SvgIcon from '@mui/material/SvgIcon';

function BellIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 2.25C11.1709 2.25 10.5 2.9209 10.5 3.75C10.5 3.81445 10.5146 3.87598 10.5234 3.9375C7.93066 4.59961 6 6.95508 6 9.75V16.5C6 16.9248 5.6748 17.25 5.25 17.25H4.5V18.75H9.89062C9.80566 18.9873 9.75 19.2363 9.75 19.5C9.75 20.7334 10.7666 21.75 12 21.75C13.2334 21.75 14.25 20.7334 14.25 19.5C14.25 19.2363 14.1943 18.9873 14.1094 18.75H19.5V17.25H18.75C18.3252 17.25 18 16.9248 18 16.5V9.96094C18 7.14258 16.1162 4.62891 13.4766 3.9375C13.4854 3.87598 13.5 3.81445 13.5 3.75C13.5 2.9209 12.8291 2.25 12 2.25ZM11.6719 5.25C11.7803 5.24121 11.8887 5.25 12 5.25C12.0469 5.25 12.0938 5.25 12.1406 5.25C14.5898 5.32324 16.5 7.4707 16.5 9.96094V16.5C16.5 16.7637 16.5557 17.0127 16.6406 17.25H7.35938C7.44434 17.0127 7.5 16.7637 7.5 16.5V9.75C7.5 7.36816 9.33398 5.41992 11.6719 5.25ZM12 18.75C12.4219 18.75 12.75 19.0781 12.75 19.5C12.75 19.9219 12.4219 20.25 12 20.25C11.5781 20.25 11.25 19.9219 11.25 19.5C11.25 19.0781 11.5781 18.75 12 18.75Z' />
        </SvgIcon>
    );
}
export default BellIcon;
