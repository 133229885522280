import SvgIcon from '@mui/material/SvgIcon';

function SyncIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 3C8.16504 3 4.96289 5.37012 3.65625 8.71875L5.03906 9.28125C6.13184 6.48047 8.7832 4.5 12 4.5C14.4316 4.5 16.5996 5.69238 17.9531 7.5H15V9H20.25V3.75H18.75V6.07031C17.1064 4.18652 14.6777 3 12 3ZM18.9609 14.7188C17.8682 17.5195 15.2168 19.5 12 19.5C9.54199 19.5 7.38281 18.29 6.02344 16.5H9V15H3.75V20.25H5.25V17.9297C6.89062 19.79 9.2959 21 12 21C15.835 21 19.0371 18.6299 20.3438 15.2812L18.9609 14.7188Z' />
        </SvgIcon>
    );
}
export default SyncIcon;
