import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Box,
    Grid,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LogoIcon from '../../components/icons/LogoIcon';
import AuthService from '../../api/auth.service';

function SignInPage() {
    useEffect(() => {
        document.title = 'Регистрация пользователя';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница регистрации пользователя');
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Обязательное поле')
            .min(3, 'Минимум 3 символа')
            .max(60, 'Максимум 60 символов'),
        email: Yup.string()
            .required('Обязательное поле')
            .email('Некорректный email'),
        phone: Yup.string()
            .required('Обязательное поле')
            .min(10, 'Не хватает цифр в номере'),
        password: Yup.string()
            .required('Обязательное поле')
            .min(8, 'Минимум 8 символов')
            .max(40, 'Максимум 40 символов'),
        confirmPassword: Yup.string()
            .required('Обязательное поле')
            .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
        terms: Yup.bool().oneOf([true], 'Обязательное поле'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const navigate = useNavigate();

    const onSubmit = (data) => {
        AuthService.register(
            data.name,
            data.email,
            data.phone,
            data.terms,
            data.password,
            data.confirmPassword
        ).then(
            (response) => {
                setTimeout(() => {
                    navigate('/profile', { redirect: true });
                }, 2000);
                if (!response.message)
                    response.message = 'Вы успешно зарегистрировались!';
                setSnackBarSeverity('success');
                setSnackBarMessage(response.message);
                setOpen(true);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                const errorMessageEmail = JSON.stringify(
                    error.response.data.errors.email
                );

                let ermasg;

                if (errorMessageEmail) {
                    ermasg = `${resMessage}: Email уже занят`;
                } else {
                    ermasg = resMessage;
                }

                setSnackBarSeverity('error');
                setSnackBarMessage(ermasg);
                setOpen(true);
            }
        );
    };

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '320px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    <LogoIcon
                        sx={{
                            width: 32,
                            height: 32,
                            marginBottom: 4,
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h1'
                        sx={{ marginBottom: 1 }}
                    >
                        Регистрация
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Уже есть аккаунт?
                        <Link
                            component={RouterLink}
                            to='/sign-in'
                            variant='body2'
                            sx={{ paddingLeft: 0.5 }}
                        >
                            Войдите
                        </Link>
                    </Typography>
                </Box>
                <Box
                    component='form'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='name'
                        placeholder='Например, Иванов Иван'
                        label='Ваше имя'
                        name='name'
                        autoFocus
                        autoComplete='off'
                        {...register('name')}
                        error={!!errors.name}
                        helperText={errors?.name ? errors.name.message : null}
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='email'
                        placeholder='Например, ivan@gmail.com'
                        label='Email'
                        name='email'
                        autoComplete='off'
                        {...register('email')}
                        error={!!errors.email}
                        helperText={errors?.email ? errors.email.message : null}
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='phone'
                        placeholder='В формате +7 (999) 999-99-99'
                        label='Телефон'
                        name='phone'
                        autoComplete='off'
                        {...register('phone')}
                        error={!!errors.phone}
                        helperText={errors?.phone ? errors.phone.message : null}
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='От 8 до 40 символов'
                        name='password'
                        label='Пароль'
                        type='password'
                        id='password'
                        autoComplete='off'
                        {...register('password')}
                        error={!!errors.password}
                        helperText={
                            errors?.password ? errors.password.message : null
                        }
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='Повторите пароль'
                        name='confirmPassword'
                        label='Повтор пароля'
                        type='password'
                        id='confirmPassword'
                        autoComplete='off'
                        {...register('confirmPassword')}
                        error={!!errors.confirmPassword}
                        helperText={
                            errors?.confirmPassword
                                ? errors.confirmPassword.message
                                : null
                        }
                    />
                    <Grid container>
                        <Grid item xs>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value='true'
                                        color='primary'
                                        name='terms'
                                        {...register('terms')}
                                    />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        variant='body2'
                                        color={
                                            errors.terms ? 'error' : 'inherit'
                                        }
                                        sx={{
                                            paddingTop: '8px',
                                            fontSize: '0.875rem',
                                        }}
                                    >
                                        Даю свое согласие на обработку моих
                                        персональных данных*
                                    </Typography>
                                }
                                sx={{
                                    alignItems: 'flex-start',
                                }}
                            />
                            <br />
                            <Typography
                                component='span'
                                variant='body2'
                                color='textSecondary'
                                sx={{
                                    paddingLeft: 4,
                                    fontSize: '0.75rem',
                                    lineHeight: '0.875rem',
                                }}
                            >
                                {errors.terms
                                    ? `(${errors.terms.message})`
                                    : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        sx={{ mt: 3, mb: 2, borderRadius: 26 }}
                    >
                        Создать аккаунт
                    </Button>
                    <Typography
                        variant='body2'
                        sx={{ mt: 5 }}
                        color='text.secondary'
                        align='center'
                    >
                        {'Copyright © '}
                        <Link component={RouterLink} to='/' color='inherit'>
                            Standfin
                        </Link>{' '}
                        {new Date().getFullYear()}.
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default SignInPage;
