import InterBoldEot from './Inter-Bold.eot';
import InterBoldTtf from './Inter-Bold.ttf';
import InterBoldWoff from './Inter-Bold.woff';
import InterBoldWoff2 from './Inter-Bold.woff2';

import InterMediumEot from './Inter-Medium.eot';
import InterMediumTtf from './Inter-Medium.ttf';
import InterMediumWoff from './Inter-Medium.woff';
import InterMediumWoff2 from './Inter-Medium.woff2';

import InterRegularEot from './Inter-Regular.eot';
import InterRegularTtf from './Inter-Regular.ttf';
import InterRegularWoff from './Inter-Regular.woff';
import InterRegularWoff2 from './Inter-Regular.woff2';

import InterSemiboldEot from './Inter-SemiBold.eot';
import InterSemiboldTtf from './Inter-SemiBold.ttf';
import InterSemiboldWoff from './Inter-SemiBold.woff';
import InterSemiboldWoff2 from './Inter-SemiBold.woff2';

function fonts() {
    return (
        `@font-face {\n` +
        `    font-family: "Inter-Regular";\n` +
        `    src: url(${InterRegularEot});\n` +
        `    src: url("${InterRegularEot}?#iefix") format("embedded-opentype"),\n` +
        `         url(${InterRegularWoff2}) format("woff2"),\n` +
        `         url(${InterRegularWoff}) format("woff"),\n` +
        `         url(${InterRegularTtf}) format("ttf"),\n` +
        `    font-style: normal;\n` +
        `    font-display: swap;\n` +
        `    font-weight: 300;\n` +
        `}\n` +
        `@font-face {\n` +
        `    font-family: "Inter-Medium";\n` +
        `    src: url(${InterMediumEot});\n` +
        `    src: url("${InterMediumEot}?#iefix") format("embedded-opentype"),\n` +
        `         url(${InterMediumWoff2}) format("woff2"),\n` +
        `         url(${InterMediumWoff}) format("woff"),\n` +
        `         url(${InterMediumTtf}) format("ttf"),\n` +
        `    font-style: normal;\n` +
        `    font-display: swap;\n` +
        `    font-weight: normal;\n` +
        `}\n` +
        `@font-face {\n` +
        `    font-family: "Inter-SemiBold";\n` +
        `    src: url(${InterSemiboldEot});\n` +
        `    src: url("${InterSemiboldEot}?#iefix") format("embedded-opentype"),\n` +
        `         url(${InterSemiboldWoff2}) format("woff2"),\n` +
        `         url(${InterSemiboldWoff}) format("woff"),\n` +
        `         url(${InterSemiboldTtf}) format("ttf"),\n` +
        `    font-style: normal;\n` +
        `    font-display: swap;\n` +
        `    font-weight: 600;\n` +
        `}\n` +
        `@font-face {\n` +
        `    font-family: "Inter-Bold";\n` +
        `    src: url(${InterBoldEot});\n` +
        `    src: url("${InterBoldEot}?#iefix) format("embedded-opentype"),\n` +
        `         url(${InterBoldWoff2}) format("woff2"),\n` +
        `         url(${InterBoldWoff}) format("woff"),\n` +
        `         url(${InterBoldTtf}) format("ttf"),\n` +
        `    font-style: normal;\n` +
        `    font-display: swap;\n` +
        `    font-weight: 700;\n` +
        `}`
    );
}

export default fonts;
