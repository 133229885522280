import SvgIcon from '@mui/material/SvgIcon';

function LevelUpIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12.75 3L12.2109 3.51562L5.83594 9.89062L6.91406 10.9702L12 5.88281V19.5H4.5V21H12H13.5V5.88281L18.5859 10.9688L19.6641 9.89062L13.2891 3.51562L12.75 3Z' />
        </SvgIcon>
    );
}
export default LevelUpIcon;
