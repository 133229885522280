import SvgIcon from '@mui/material/SvgIcon';

function UserIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 3.75C9.1084 3.75 6.75 6.1084 6.75 9C6.75 10.8076 7.67285 12.4131 9.07031 13.3594C6.39551 14.5078 4.5 17.1621 4.5 20.25H6C6 18.082 7.14551 16.1953 8.85938 15.1406C9.36328 16.377 10.5908 17.25 12 17.25C13.4092 17.25 14.6367 16.377 15.1406 15.1406C16.8545 16.1953 18 18.082 18 20.25H19.5C19.5 17.1621 17.6045 14.5078 14.9297 13.3594C16.3271 12.4131 17.25 10.8076 17.25 9C17.25 6.1084 14.8916 3.75 12 3.75ZM12 5.25C14.0801 5.25 15.75 6.91992 15.75 9C15.75 11.0801 14.0801 12.75 12 12.75C9.91992 12.75 8.25 11.0801 8.25 9C8.25 6.91992 9.91992 5.25 12 5.25ZM12 14.25C12.6152 14.25 13.2012 14.3379 13.7578 14.5078C13.4971 15.2314 12.8174 15.75 12 15.75C11.1826 15.75 10.5029 15.2314 10.2422 14.5078C10.7988 14.3379 11.3848 14.25 12 14.25Z' />
        </SvgIcon>
    );
}
export default UserIcon;
