import SvgIcon from '@mui/material/SvgIcon';

function BriefcaseIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 2.25C10.6025 2.25 9.45117 3.20801 9.11719 4.5H2.25V19.5H21.75V4.5H14.8828C14.5488 3.20801 13.3975 2.25 12 2.25ZM12 3.75C12.6064 3.75 13.0723 4.04004 13.3125 4.5H10.6875C10.9277 4.04004 11.3936 3.75 12 3.75ZM3.75 6H20.25V12.75H3.75V6ZM12 10.5C11.5869 10.5 11.25 10.8369 11.25 11.25C11.25 11.6631 11.5869 12 12 12C12.4131 12 12.75 11.6631 12.75 11.25C12.75 10.8369 12.4131 10.5 12 10.5ZM3.75 14.25H20.25V18H3.75V14.25Z' />
        </SvgIcon>
    );
}
export default BriefcaseIcon;
