import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Box,
    Grid,
    Typography,
    Alert,
    Snackbar,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LogoIcon from '../../components/icons/LogoIcon';
import AuthService from '../../api/auth.service';

function SignInPage() {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    useEffect(() => {
        document.title = 'Вход в приложение';
        document
            .querySelector('meta[name="description"]')
            .setAttribute('content', 'Страница входа пользователя');
    });

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Обязательное поле')
            .email('Некорректный email'),
        password: Yup.string()
            .required('Обязательное поле')
            .min(8, 'Минимум 8 символов')
            .max(40, 'Максимум 40 символов'),
    });

    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/profile';

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        AuthService.login(data.email, data.password).then(
            (response) => {
                setTimeout(() => {
                    navigate(fromPage, { replace: true });
                }, 2000);
                setSnackBarSeverity('success');
                if (!response.message) {
                    setSnackBarMessage('Вы успешно вошли');
                } else {
                    setSnackBarMessage(response.message);
                }
                setOpenSnackBar(true);
            },
            (error) => {
                const errorMsg =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setSnackBarSeverity('error');
                setSnackBarMessage(errorMsg);
                setOpenSnackBar(true);
            }
        );
    };

    return (
        <>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackBar}
                    severity={snackBarSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    width: {
                        xs: '100%',
                        md: '320px',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        alignSelf: 'flex-start',
                    }}
                >
                    <LogoIcon
                        sx={{
                            width: 32,
                            height: 32,
                            marginBottom: 4,
                        }}
                    />
                    <Typography
                        component='h1'
                        variant='h1'
                        sx={{ marginBottom: 1 }}
                    >
                        Вход
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Нет аккаунта?
                        <Link
                            component={RouterLink}
                            to='/sign-up'
                            variant='body2'
                            sx={{ paddingLeft: 0.5 }}
                        >
                            Зарегистрируйтесь
                        </Link>
                    </Typography>
                </Box>
                <Box
                    component='form'
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        id='email'
                        placeholder='Например, ivan@gmail.com'
                        label='Email'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        {...register('email')}
                        error={!!errors.email}
                        helperText={errors?.email ? errors.email.message : null}
                    />
                    <TextField
                        margin='normal'
                        required
                        size='small'
                        fullWidth
                        placeholder='От 8 до 40 символов'
                        name='password'
                        label='Пароль'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        {...register('password')}
                        error={!!errors.password}
                        helperText={
                            errors?.password ? errors.password.message : null
                        }
                    />
                    <Grid container>
                        <Grid item xs>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value='true'
                                        color='primary'
                                        name='rememberMe'
                                        {...register('rememberMe')}
                                    />
                                }
                                label='Запомнить меня'
                            />
                        </Grid>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Link
                                component={RouterLink}
                                to='/restore-password'
                                variant='body2'
                            >
                                Забыли пароль?
                            </Link>
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        size='large'
                        sx={{ mt: 3, mb: 2, borderRadius: 26 }}
                    >
                        Войти
                    </Button>
                    <Typography
                        variant='body2'
                        sx={{ mt: 5 }}
                        color='text.secondary'
                        align='center'
                    >
                        {'Copyright © '}
                        <Link component={RouterLink} to='/' color='inherit'>
                            Standfin
                        </Link>{' '}
                        {new Date().getFullYear()}.
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default SignInPage;
