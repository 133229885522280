import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';
import {
    List,
    ListItemIcon,
    ListItemText,
    Collapse,
    ListItemButton,
    Box,
    Link,
} from '@mui/material';
import AngleUpIcon from '../../icons/AngleUpIcon';
import AngleDownIcon from '../../icons/AngleDownIcon';

function SidebarMenuItem({ item }) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box component='li' sx={{ marginBottom: 3 }}>
            <ListItemButton
                onClick={handleClick}
                sx={{
                    borderRadius: '6px',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    ...(open && {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }),
                    // ...(active && {
                    //     backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    // }),
                }}
            >
                <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                        fontSize: '12px',
                        lineHeight: '24px',
                        textTransform: 'uppercase',
                        fontWeight: '600',
                        color: 'primary.light',
                    }}
                />
                {open ? (
                    <AngleUpIcon
                        sx={{ fill: '#fff', width: '16px', height: '16px' }}
                    />
                ) : (
                    <AngleDownIcon
                        sx={{ fill: '#fff', width: '16px', height: '16px' }}
                    />
                )}
            </ListItemButton>
            {item.children && (
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List
                        component='div'
                        disablePadding
                        sx={{ marginTop: '4px' }}
                    >
                        {item.children.map((childrenItem) => (
                            <ListItemButton
                                key={childrenItem.id}
                                sx={{
                                    borderRadius: '6px',
                                    '&:hover': {
                                        backgroundColor:
                                            'rgba(255, 255, 255, 0.1)',
                                    },
                                    // ...(active && {
                                    //     backgroundColor:
                                    //         'rgba(255, 255, 255, 0.1)',
                                    // }),
                                }}
                            >
                                <Link
                                    to={childrenItem.link}
                                    component={ReactLink}
                                    underline='none'
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '0',
                                            marginRight: '16px',
                                        }}
                                    >
                                        {childrenItem.icon && childrenItem.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={childrenItem.name}
                                        primaryTypographyProps={{
                                            lineHeight: '24px',
                                            color: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                    />
                                </Link>
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            )}
        </Box>
    );
}

SidebarMenuItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
                // eslint-disable-next-line react/forbid-prop-types
                icon: PropTypes.object,
            })
        ),
    }).isRequired,
    // location: PropTypes.string.isRequired,
};

export default SidebarMenuItem;
