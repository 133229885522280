import SvgIcon from '@mui/material/SvgIcon';

function CloseIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M5.41406 4.33594L4.33594 5.41406L10.9219 12L4.33594 18.5859L5.41406 19.6641L12 13.0781L18.5859 19.6641L19.6641 18.5859L13.0781 12L19.6641 5.41406L18.5859 4.33594L12 10.9219L5.41406 4.33594Z' />
        </SvgIcon>
    );
}
export default CloseIcon;
