import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './routes/Routes';
import theme from './settings/theme';
import RemoveTrailingSlash from './routes/RemoveTrailingSlash';

function App() {
    return (
        <BrowserRouter>
            <RemoveTrailingSlash />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
