import { Box, Link, Typography } from '@mui/material';

function SidebarFooter() {
    return (
        <Box
            component='div'
            sx={{
                marginTop: 'auto',
                padding: '0 20px 20px',
            }}
        >
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    component='span'
                    variant='body2'
                    sx={{
                        color: 'rgba(255, 255, 255, 0.7)',
                    }}
                >
                    Ваш тариф:
                </Typography>
                <Typography
                    component='span'
                    variant='body2'
                    sx={{
                        color: 'rgba(255, 255, 255, 1)',
                        marginLeft: 0.5,
                        fontWeight: '600',
                    }}
                >
                    Premium
                </Typography>
            </Box>
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '12px',
                }}
            >
                <Typography
                    component='span'
                    variant='caption'
                    sx={{
                        color: 'rgba(255, 255, 255, 0.7)',
                    }}
                >
                    Оплачен до:
                </Typography>
                <Typography
                    component='span'
                    variant='caption'
                    sx={{
                        color: 'rgba(255, 255, 255, 0.7)',
                        marginLeft: 0.5,
                        fontWeight: '600',
                    }}
                >
                    22.02.2023
                </Typography>
            </Box>
            <Box
                component='div'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '12px',
                }}
            >
                <Link
                    href='/tariffes'
                    variant='body2'
                    color='primary.light'
                    sx={{
                        fontWeight: '600',
                    }}
                >
                    Сменить тариф
                </Link>
            </Box>
        </Box>
    );
}

export default SidebarFooter;
