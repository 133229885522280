import SvgIcon from '@mui/material/SvgIcon';

function ChartPieIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d='M12 2.25C6.62402 2.25 2.25 6.62402 2.25 12C2.25 17.376 6.62402 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.62402 17.376 2.25 12 2.25ZM11.1562 3.79688C11.1885 3.79395 11.2178 3.7998 11.25 3.79688V12.3047L11.4609 12.5391L17.2969 18.3516C15.8672 19.5469 14.0156 20.25 12 20.25C7.43555 20.25 3.75 16.5645 3.75 12C3.75 7.71973 6.99023 4.21875 11.1562 3.79688ZM12.75 3.79688C16.7139 4.1543 19.8457 7.28613 20.2031 11.25H12.75V3.79688ZM13.8281 12.75H20.2031C20.0479 14.4697 19.3975 16.0459 18.3516 17.2969L13.8281 12.75Z' />
        </SvgIcon>
    );
}
export default ChartPieIcon;
